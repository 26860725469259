import {ordersToDates} from '@wix/events-moment-commons'
import {getLocale} from '@wix/wix-events-commons-statics'
import {updateDates} from '../../commons/actions/dates'
import {SET_BASE_ENVIRONMENT} from '../../commons/actions/environment'
import {EventsList} from '../../commons/enums'
import {getFullLocale} from '../../commons/selectors/environment'
import {GET_EVENTS} from '../actions/events'
import {MemberPageState} from '../types/state'

export const datesMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case SET_BASE_ENVIRONMENT: {
      const state: MemberPageState = store.getState()
      const locale = getLocale(action.payload.locale)
      store.dispatch(updateDates({eventsList: getAllEvents(state), locale}))
      break
    }
    case GET_EVENTS.SUCCESS: {
      const state: MemberPageState = store.getState()
      const locale = getFullLocale(state)
      const orders = action.payload.orders
      const dates = ordersToDates(orders, locale)
      store.dispatch(updateDates({eventsList: action.payload.events, commonDates: dates}))
      break
    }
    default:
      break
  }
  next(action)
}

export const getAllEvents = (state: MemberPageState) => {
  return [
    ...state.events[EventsList.PAST],
    ...state.events[EventsList.UPCOMING],
    ...state.demoEvents[EventsList.PAST],
    ...state.demoEvents[EventsList.UPCOMING],
  ].map((event) => event.event)
}
